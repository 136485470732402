import React from "react";
import { Link } from "gatsby";

import picDocApp from "../assets/images/docapp.jpg";
import ImgArrow from "../assets/images/arrow.svg";

const Banner = (props) => (
    <section id="banner" className="major" style={{backgroundImage: `url(${picDocApp})`, position: "relative"}}>
        <div className="inner">
            <header className="major">
                <h1>Welkom bij <span className="type-mecosud">Mecosud B.V.</span></h1>
            </header>
            <div className="content">
                <p>Wij verzorgen een breed scala aan (digitale) publicaties en producten voor diverse sectoren.</p>
            </div>
            {/* <ul className="actions">
                <li>
                  <Link to="/over-ons" className="button next">
                    Verder lezen
                  </Link>
                </li>
              </ul> */}
        </div>
        <div style={{ position: "absolute", bottom: "-10px", right: "30px", color: "white", zIndex: "10", display: "flex" }}>
            <div style={{ backgroundColor: "white", color: "black", fontWeight: "500", fontSize: "80%", height: "30px", padding: "2px 10px", margin: "0 10px", borderRadius: "4px" }}>
            Een aantal van onze producten en merken
            </div>
            <img src={ImgArrow} style={{ transform: "scaleX(-1)", position: "relative", top: "10px" }} />
        </div>
    </section>
)

export default Banner;
