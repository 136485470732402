import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import Banner from "../components/Banner";

import picBooks from "../assets/images/books.jpg";
import picFlowchart from "../assets/images/flowchart-2.png";
import picGetEarcons from "../assets/images/logo-getearcons.svg";
import logoVzc from "../assets/images/logo-vzc.png";

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Mecosud B.V. | Wij verzorgen publicaties en producten voor de gezondheidszorg en educatieve sectoren."
          meta={[
            {
              name: "description",
              content:
                "Mecosud B.V. verzorgt publicaties en producten voor de gezondheidszorg en educatieve sectoren.",
            },
            {
              name: "keywords",
              content: "gezondheidszorg, beslisbomen, flowchart, medische apps",
            },
          ]}
        ></Helmet>

        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${picFlowchart})` }}>
              <header className="major">
                <h3>inFlow interactive flowcharts</h3>
                <p>Interactieve beslisbomen voor jouw website of app</p>
              </header>
              <Link
                to="/inflow-interactive-flowcharts"
                className="link primary"
              ></Link>
            </article>
            <article style={{ backgroundImage: `url(${picBooks})` }}>
              <header className="major">
                <h3>van Zuiden Communications</h3>
                <p>Onze uitgeverij voor de gezondheidszorg (Sinds 1991)</p>
              </header>
              <Link
                to="/van-zuiden-communications"
                className="link primary"
              ></Link>
            </article>
            <article
              style={{
                backgroundColor: "white",
                backgroundImage: `url(${picGetEarcons})`,
                backgroundSize: "80%",
                cursor: "not-allowed",
              }}
            >
              <header className="major">
                <h3>GetEarcons.com</h3>
                <p>Binnenkort online!</p>
              </header>
              {/* <Link
                to="/inflow-interactive-flowcharts"
                className="link primary"
              ></Link> */}
            </article>
          </section>
          <section id="two">
            <div className="inner">
              <header className="major">
                <h2>Over Mecosud B.V.</h2>
              </header>
              <p>
                Wij hebben een jarenlange ervaring als uitgever van (medische)
                richtlijnen, boeken en tijdschriften. Daarnaast ontwerpen en
                ontwikkelen wij veel digitale producten zoals apps & websites.
              </p>
              <p>
                Door de combinatie van onze ervaring én grote voorliefde voor het maak-proces zij wij
                een creatieve partner bij het realiseren van doeltreffende en gebruiksvriendelijke
                producten. Zowel voor print, als voor apps of websites, of een combinatie van meerdere media.
              </p>
              <ul className="actions">
                <li>
                  <Link to="/over-ons" className="button next">
                    Verder lezen
                  </Link>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default HomeIndex;
